import { createGlobalStyle } from "styled-components";
import px2vw from "../utils/px2vw";

export const Global = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    :root {
        font-family:'Noto Sans JP';
        font-size: ${px2vw(24)};

        @media (min-width: 768px) {
            font-size: ${px2vw(18)};
        }

        @media (min-width: 1024px) {
            font-size: ${px2vw(16)};
        }
    }
    textarea{
        font-family:'Noto Sans JP';
    }

    .container::-webkit-scrollbar-track {
        background: #000000;
    }
`;

export default Global;